<template>
  <div>
    <b-card
      no-body
    >
      <base-actions-table
        :columns="fields"
        :records="records"
        :busy="isBusy"
        :is-processing="isProcessing"
        :module="module"
        @creating="handleCreating"
        @editing="handleEditing"
        @deleting="handleDeleting"
      >
        <template v-slot:extendedactions="data">
          <b-button
            v-if="hasPermission(`${module}.details`)"
            variant="info"
            class="btn-icon mr-25"
            size="sm"
            @click.prevent="() => $emit('editing', data.item)"
          >
            <feather-icon
              icon="ListIcon"
            />
          </b-button>
        </template>
      </base-actions-table>
    </b-card>
    <b-modal
      id="patients-modal"
      ref="addpatients"
      title="Paciente"
      size="lg"
      hide-footer
    >
      <b-card-text>
        <patient-form
          ref="form"
          :record="selectedRecord"
          @save="save"
          @edit="edit"
        />
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
  import {
    BModal,
    BCardText,
    BCard,
    BButton,
  } from 'bootstrap-vue'
  import CRUDMixin from '@/mixins/CRUDMixin'
  import PermissionMixin from '@/mixins/PermissionMixin'
  import PatientForm from './PatientForm'
  import BaseActionsTable from '@/components/base/BaseActionsTable'

  export default {
    name: 'Patients',
    inject: ['patientsRepository'],
    components: {
      BCard,
      BButton,
      BCardText,
      BModal,
      PatientForm,
      BaseActionsTable,
    },
    mixins: [CRUDMixin, PermissionMixin],
    data() {
      return {
        fields: [
          {
            key: 'document_type.short_name',
            label: 'Tipo documento',
          },
          {
            key: 'document_number',
            label: 'Identificación',
          },
          {
            key: 'first_name',
            label: 'Nombre',
          },
          {
            key: 'last_name',
            label: 'Apellido',
          },
          {
            key: 'actions',
            label: 'Acciones',
          },
        ],
        records: [],
        filter: null,
        filterOn: [],
        selectedRecord: null,
        repository: 'patientsRepository',
        module: 'patients',
        isBusy: false,
      }
    },

    async mounted() {
      const me = this
      await me.handleLoadData()
    },

    methods: {
      handleCreating() {
        this.selectedRecord = null
        this.$bvModal.show('patients-modal')
      },

      handleEditing(record) {
        this.selectedRecord = record
        this.$bvModal.show('patients-modal')
      },

      handleDeleting(record) {
        this.del(record.id)
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
